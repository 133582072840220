import { render, staticRenderFns } from "./modal-change-city.vue?vue&type=template&id=3038972f&scoped=true"
import script from "./modal-change-city.vue?vue&type=script&lang=js"
export * from "./modal-change-city.vue?vue&type=script&lang=js"
import style0 from "./modal-change-city.vue?vue&type=style&index=0&id=3038972f&prod&lang=scss"
import style1 from "./modal-change-city.vue?vue&type=style&index=1&id=3038972f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3038972f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VSelect: require('/app/node_modules/vuetify/lib/components/VSelect/VSelect.js').default,VRadio: require('/app/node_modules/vuetify/lib/components/VRadioGroup/VRadio.js').default,VRadioGroup: require('/app/node_modules/vuetify/lib/components/VRadioGroup/VRadioGroup.js').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VRadio,VRadioGroup,VSelect})
