
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CNotifications",

  computed: {
    ...mapGetters({
      notifications: "c-notifications/getNotificationsToShow",
      notificationsPosition: "c-notifications/getNotificationPosition",
    }),
  },

  watch: {
    notifications: {
      handler: function (notifications) {
        notifications.forEach((notification) => {
          if (notification.timeout) {
            setTimeout(() => {
              this.removeNotification(notification);
            }, notification.timeout);
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      removeNotification: "c-notifications/removeNotification",
    }),
  },
};
