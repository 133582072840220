
import breadcrumbs from "~/components/global/breadcrumbs.vue";

export default {
  components: { breadcrumbs },

  computed: {
    isMobile() {
      return this.$store.state.global.mobile;
    },
  },

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("notifications/retrieveSidebarNotifications");
    }
  },
};
