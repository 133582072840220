
export default {
  name: "Slug",

  middleware: ["isActive"],

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("notifications/retrieveSidebarNotifications");
    }
  },
};
