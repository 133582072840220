
import { mapGetters } from "vuex";
import { spacesToDashes } from "~/common/helpers";

export default {
  data() {
    return {
      city: {},
      gender: "female",
      district: { id: "", title: "All" },
    };
  },

  async fetch() {
    if (this.currentCity.id) {
      this.city = this.cities.find(
        (item) => item.title === this.currentCity.name
      );
    }
    if (this.currentDistrict) {
      this.district = this.districts.find(
        (item) => item.title === this.currentDistrict
      );
    }
    if (!Object.keys(this.filtersList).length) {
      await this.$store.dispatch("profiles/retrieveFilters");
    }
  },

  computed: {
    ...mapGetters({
      currentCity: "global/getCurrentCity",
      currentDistrict: "global/getCurrentDistrict",
      statusModalChangeCity: "global/getStatusModalChangeCity",
      filtersList: "profiles/getFiltersList",
    }),

    cities() {
      return this.filtersList.cities || [];
    },

    isCitySelected() {
      return this.city.id;
    },

    districts() {
      return [
        ...[{ id: "", title: "All" }],
        ...(this.filtersList.districts || []).filter(
          (item) => item.city_id === this.city.id
        ),
      ];
    },
  },

  watch: {
    city() {
      this.district = { id: "", title: "All" };
    },
    statusModalChangeCity(val) {
      if (val && this.currentDistrict) {
        this.district = this.districts.find(
          (item) => item.title === this.currentDistrict
        );
      }
    },
  },

  methods: {
    changeLocation() {
      const district = this.district.title === "All" ? "" : this.district.title;
      const currentCity = {
        id: this.city.id,
        name: this.city.title,
      };
      this.$store.commit("global/setCurrentCity", currentCity);
      this.$cookies.set("current-city", JSON.stringify(currentCity), {
        path: "/",
        maxAge: 60 * 60 * 24 * 400,
      });
      this.$cookies.set("current-district", district == "All" ? "" : district, {
        path: "/",
        maxAge: 60 * 60 * 24 * 400,
      });
      this.$store.commit("global/setCurrentDistrict", district);

      let path = spacesToDashes((this.city.title + " " + district).trim());
      path += this.gender === "all" ? "" : `/${this.gender}`;
      this.$router.push({ path: "/" + path });

      this.openedModal();
    },

    openedModal() {
      this.$store.commit("global/setModalChangeCityStatus");
    },

    goHome() {
      this.$router.push({ path: "/" });
      setTimeout(() => {
        this.$store.commit("global/setModalChangeCityStatus");
      }, 100);
    },
  },
};
