
import { mapGetters } from "vuex";

export default {
  name: "Catalog",

  middleware: ["catalog-routes", "isActive"],

  data() {
    return {
      showPartHeight: 0,
    };
  },

  computed: {
    ...mapGetters({
      headerHidden: "global/getPaddingForHiddenHeader",
    }),
  },

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("notifications/retrieveSidebarNotifications");
    }
  },

  methods: {
    valueHeightShowPart(value) {
      this.showPartHeight = value;
    },
  },
};
