
import { mdiTriangle } from "@mdi/js";
import VueResponsiveMenu from "vue-responsive-menu";
import { mapGetters } from "vuex";

export default {
  components: { VueResponsiveMenu },

  props: ["menu"],

  data() {
    return {
      mdiTriangle,
      catalogItemTitle: "",
    };
  },

  computed: {
    ...mapGetters({
      defaultFilters: "profiles/getDefaultFilters",
    }),
    adaptiveMenu() {
      return this.menu.slice();
    },
    catalogItemTitles() {
      return {
        female: this.$t("female"),
        male: this.$t("male"),
        transgender: this.$t("shemale"),
      };
    },
    isCatalog() {
      return this.$route.name.includes("catalog");
    },
  },

  watch: {
    currentDistrict() {
      console.log("currentDistrict", this.currentDistrict);
    },
    $route: {
      handler(newRoute) {
        if (newRoute.name?.includes("catalog")) {
          this.catalogMenuItemTitle();
        } else {
          this.catalogItemTitle = this.$t("female");
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    catalogMenuItemTitle() {
      this.catalogItemTitle = this.defaultFilters.gender
        ? this.catalogItemTitles[this.defaultFilters.gender]
        : this.$t("all");
    },

    processNavLink(link) {
      if (
        this.isCatalog &&
        this.defaultFilters.gender &&
        link.includes("categories")
      ) {
        return `${link}/${this.defaultFilters.gender}`;
      }
      return link;
    },
  },
};
