import { render, staticRenderFns } from "./c-notifications.vue?vue&type=template&id=1ee3295a"
import script from "./c-notifications.vue?vue&type=script&lang=js"
export * from "./c-notifications.vue?vue&type=script&lang=js"
import style0 from "./c-notifications.vue?vue&type=style&index=0&id=1ee3295a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VAlert: require('/app/node_modules/vuetify/lib/components/VAlert/VAlert.js').default,VApp: require('/app/node_modules/vuetify/lib/components/VApp/VApp.js').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VAlert,VApp})
