import { render, staticRenderFns } from "./static_pages.vue?vue&type=template&id=0c1103a0"
import script from "./static_pages.vue?vue&type=script&lang=js"
export * from "./static_pages.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IsAdult: require('/app/components/isAdult.vue').default,TheHeader: require('/app/components/TheHeader.vue').default,Breadcrumbs: require('/app/components/global/breadcrumbs.vue').default,Footer: require('/app/components/Footer.vue').default})
