
import { mapGetters } from "vuex";

export default {
  name: "Default",

  middleware: ["isActive"],

  computed: {
    ...mapGetters({
      headerHidden: "global/getPaddingForHiddenHeader",
    }),
  },

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("notifications/retrieveSidebarNotifications");
    }
  },
};
