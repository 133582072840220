
export default {
  middleware: ["isActive", "processSplashFilters"],

  head() {
    return {
      script: [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "Emiratescort",
            url: this.$config.baseUrlApp,
            logo: `${this.$config.baseUrlApp}/Symmetrical-logo.png`,
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+380950000000",
              contactType: "customer service",
              areaServed: "AE",
              availableLanguage: "en",
            },
          }),
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
    };
  },

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("notifications/retrieveSidebarNotifications");
    }
  },
};
